.playlist-item {
	height: 80px;
	font-weight: 600;
	font-size: 1rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
	transition: 0.8s;
}

.playlist-item:hover {
	background-color: white;
	cursor: pointer;
}

.playlist-image {
	height: 100%;
	overflow: hidden;
}
