@font-face {
	font-family: "MuliBold";
	src: local("MuliBold"), url("./fonts/Muli/Muli-Bold.ttf") format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "Muli";
	src: local("Muli"), url("./fonts/Muli/Muli.ttf") format("truetype");
	font-weight: bold;
}
@font-face {
	font-family: "MuliLight";
	src: local("MuliLight"),
		url("./fonts/Muli/Muli-Light.ttf") format("truetype");
	font-weight: bold;
}
